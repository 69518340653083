var date = new Date();
var retina = window.devicePixelRatio > 1;
var ws = null;
var pingMessage = {'type': 'ping'};
var wsKeepAliveTimer;
var username = "anonymous";

/*String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.startsWith = function (str) {
    return (this.match("^" + str) == str)
}

String.prototype.endsWith = function (s) {
    return this.length >= s.length && this.substr(this.length - s.length) == s;
}*/

 function padzero(n) {
    return n < 10 ? '0' + n : n;
}

 function pad2zeros(n) {
    if (n < 100) {
        n = '0' + n;
    }
    if (n < 10) {
        n = '0' + n;
    }
    return n;
}

 function toISOString(d) {
    return d.getUTCFullYear() + '-' + padzero(d.getUTCMonth() + 1) + '-' + padzero(d.getUTCDate()) + 'T' + padzero(d.getUTCHours()) + ':' + padzero(d.getUTCMinutes()) + ':' + padzero(d.getUTCSeconds()) + '.' + pad2zeros(d.getUTCMilliseconds()) + 'Z';
}

$.expr[":"].containsExact = function (obj, index, meta, stack) {
    return (obj.textContent || obj.innerText || $(obj).text() || "") == meta[3];
};

Object.defineProperty(Number.prototype, 'fileSize', {value: function (a, b, c, d) {
        return (a = a ? [1e3, 'k', 'B'] : [1024, 'K', 'iB'], b = Math, c = b.log,
                d = c(this) / c(a[0]) | 0, this / b.pow(a[0], d)).toFixed(1)
                + ' ' + (d ? (a[1] + 'MGTPEZY')[--d] + a[2] : 'Bytes');
    }, writable: false, enumerable: false});

jQuery.fn.center = function () {

    this.css("position","absolute");
    this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) +
                                                $(window).scrollTop()) + "px");
    this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
                                                $(window).scrollLeft()) + "px");
    return this;

};

 function toggleTabSpinnerIcon(list, on) {

    $('.tab-spinner').hide();
    $('.tab-icon').show();

    if (on) {
        $('.tab-spinner.' + list).toggle(0);
        $('.tab-icon.' + list).toggle(0);
    }

}

 function isChrome() {

    var isChromium = window.chrome,
            vendorName = window.navigator.vendor;

    if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc.") {
        return true;
    } else {
        return false;
    }

}

 function buildPreviewUrl(version, ordernumber, page, subfolder, uid, defaultCMYKProfile) {
    return urlBase + 'servlet/preview?action=get_image&version=softproof&ordernumber=200946&page=11&subfolder=PDF_HOLD&uid=77166b75&default_cmyk_profile=ISOcoated_v2_eci.icc';
}

 function pointsToEvenMm(points) {
    return Math.round(points * 0.352777778);
}

 function hideControls() {

    $('#header').hide();
    $('#content-box').css('top', '-24px');

}

 function formatDateTimeFromTimestamp(timestamp) {

    var datetime = new Date(timestamp);

    if ($.formatDateTime('yy-mm-dd', datetime) === $.formatDateTime('yy-mm-dd', new Date())) {
        return 'Today ' + $.formatDateTime('hh:ii', datetime);
    } else {
        return $.formatDateTime('yy-mm-dd hh:ii', datetime);
    }

}

 function l8connect(address) {

    if ('WebSocket' in window) {
        ws = new WebSocket(address);
    } else if ('MozWebSocket' in window) {
        ws = new MozWebSocket(address);
    }

    ws.onopen = function () {

        connectMain();
        onL8Connect();

        wsKeepAliveTimer = setInterval(function () {
            validateConnection();
        }, 20000);

    };

    ws.onclose = function () {

        clearInterval(wsKeepAliveTimer);

        validateConnection();

        setTimeout(l8connect, 1000, address);

    };

    ws.addEventListener("message", function (event) {

//        console.log(new Date() + " l8: " + event.data);

    });

}

 function validateConnection() {

    $.getJSON(urlBase + 'servlet/session', {
        action: 'validate'
    }, function (valid) {

        if (valid) {
            ws.send(JSON.stringify(pingMessage));
        } else {
            location.reload();
        }

    }).error(function () {
        location.reload();
    });

}

 function getModuleParameter(index) {

    var parameterData = document.URL.replace(connectUrlBase + module, '').replace(/#.*/, '');

    if (parameterData.length > 1
            && parameterData.indexOf('/') > -1) {

        return parameterData.split('/')[index + 1];

    } else {

        return undefined;

    }

}

 function getHashParameter() {

    var hashParameter = document.URL.split('#')[1];

    if (hashParameter !== undefined
            && hashParameter.length > 0) {
        return hashParameter;
    } else {
        return '';
    }

}

 function createArray(length) {
    var arr = new Array(length || 0),
            i = length;

    if (arguments.length > 1) {
        var args = Array.prototype.slice.call(arguments, 1);
        while (i--)
            arr[length - 1 - i] = createArray.apply(this, args);
    }

    return arr;
}

 function getSessionData() {

    var retval = new Array();

    $.ajax({
        type: 'GET',
        url: urlBase + 'servlet/session',
        dataType: 'json',
        success: function (data) {

            retval = data;

        },
        data: {
            action: 'get_session'},
        async: false
    });

    return retval;

}

 function logout() {

    ws.close();

    $.getJSON(urlBase + 'servlet/session', {
        action: 'invalidate'
    }, function () {

        document.location = connectUrlBase;

    });

}

 function getScript(url, callback)
{
    jQuery.ajax({
        type: "GET",
        url: url + '?v=' + version,
        success: callback,
        dataType: "script",
        cache: true
    });
}
;

var truncateString = function (string, length, limit) {

    var retval = string;

    if (!limit) {

        limit = length;

    }

    if (string.length > limit) {

        retval = string.substring(0, length) + "<span class=\"truncation-mark\">\u2026</span>";

    }

    return retval;

};

var getUniqueIdFromServer = function () {

    var id;

    $.ajax({
        async: false,
        url: urlBase + 'servlet/unique_id?action=get_timestamp',
        dataType: 'json',
        context: document.body,
        success: function (data) {
            id = data;
        }
    });

    return id;

};

var storePreferredCurrency = function (currency) {

    localStorage.setItem(username + "_preferred_currency", currency);

}

var fetchPreferredCurrency = function () {

    if (localStorage.getItem(username + "_preferred_currency") === null) {
        return 'EUR';
    } else {
        return localStorage.getItem(username + "_preferred_currency");
    }

}

var setNavigationPosition = function (position) {

    window.history.pushState(null, $('title').html(), position);

    storeNavigationPosition();

}

var storeNavigationPosition = function () {

    localStorage.setItem(username + "_navigation_position", document.URL);

};

var fetchStoredNavigationPosition = function () {

    return localStorage.getItem(username + "_navigation_position");

};


var setName = function (name) {

    localStorage.setItem(username + "_name", name);

};

var setEmail = function (email) {

    localStorage.setItem(username + "_email", email);

};



var getEmail = function () {

    return localStorage.getItem(username + "_email");

};

var setOrderFilterText = function (filterOrderText) {
    localStorage.setItem("_filterordertext", filterOrderText);
};

var setOrderFilterChkStatus = function (isChecked) {
    localStorage.setItem("_filterorderchkstatus", isChecked);
};

var getOrderFilterText = function () {
    return localStorage.getItem("_filterordertext");
};

var getOrderFilterChkStatus = function () {
    return localStorage.getItem("_filterorderchkstatus");
};

var userHasReadTip = function (tip) {

    return localStorage.getItem(username + "_tip_" + tip) !== null;

};

var setUserHasReadTip = function (tip, hasRead) {

    if (hasRead) {
        localStorage.setItem(username + "_tip_" + tip, 'true');
    } else {
        localStorage.removeItem(username + "_tip_" + tip);
    }

};

var showUserSettingsDialog = function () {

    if (getName !== null || getEmail !== null) {

        $('#userSettingsDialogNoData').hide();
        $('#userSettingsDialogChangeData').show();

        $('#name').val(getName);
        $('#email').val(getEmail);

    }

    $('#userSettingsDialog').dialog({
        closeOnEscape: false,
        open: function (e, ui) {

            $(".ui-dialog-titlebar-close").hide();

            $(this).keypress(function (e) {
                if (e.keyCode === 13) {
                    $('.ui-dialog-buttonpane button:last').trigger('click');
                }
            });

        },
        close: function (e, ui) {

            $('#userSettingsDialogNoData').hide();
            $('#userSettingsDialogChangeData').show();

        },
        modal: true,
        resizable: false,
        show: "fade",
        hide: "fade",
        autoOpen: false,
        width: 441,
        buttons: {
            "Ok": function () {

                var bValid = true;
                $('#name').removeClass("ui-state-error");
                $('#email').removeClass("ui-state-error");

                bValid = bValid && checkLength($('#name'), "Namnet", 2, 80);
                bValid = bValid && checkLength($('#email'), "E-postadressen", 6, 80);

                // From jquery.validate.js (by joern), contributed by Scott Gonzalez: http://projects.scottsplayground.com/email_address_validation/
                bValid = bValid && checkRegexp($('#email'), /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i, "E-postadressen är ogiltig.");

                if (bValid) {

                    setName($('#name').val());
                    setEmail($('#email').val());

                    $(this).dialog("close");

                }
            }
        }
    });

    $('#userSettingsDialog').dialog('open');
    $('#name').focus();

};

var checkLength = function (o, n, min, max) {

    if (o.val().length > max || o.val().length < min) {
        o.addClass("ui-state-error");
        updateTips(n + " måste bestå av minst " +
                min + " och max " + max + " tecken.");
        o.focus();
        return false;
    } else {
        return true;
    }

};

var updateTips = function (t) {
    $('.validateTips')
            .text(t)
            .addClass("ui-state-highlight");
    setTimeout(function () {
        $('.validateTips').removeClass("ui-state-highlight", effectDurationDenominator * 8);
    }, effectDurationDenominator * 2);
};

var checkRegexp = function (o, regexp, n) {

    if (!(regexp.test(o.val()))) {
        o.addClass("ui-state-error");
        updateTips(n);
        return false;
    } else {
        return true;
    }

};

hashCode = function (s) {
    return s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }, 0);
};

$.urlParam = function (name) {
    var results = new RegExp('[\\?&amp;]' + name + '=([^&amp;#]*)').exec(window.location.href);
    return results[1] || 0;
};

 function createRightAlignedColumnElement(id, label, resizable, parent, sortingColumn) {

    var res = $('<span id="' + id + '" class="' + id + ' list-cell header-label">');

    if (sortingColumn) {

        var sortingIcon = 'ui-icon-triangle-1-n';

        if (currentSortingOrder[parent.attr('id')] === 'desc') {
            sortingIcon = 'ui-icon-triangle-1-s';
        }

        res.append('<span class="header-sort-order-icon-box-right"><span class="header-sort-order-icon ui-icon ' + sortingIcon + '"></span></span>' + label);
    } else {
        res.append('<span class="header-sort-order-icon-box-right"><span class="header-sort-order-icon icon-placeholder"></span></span>' + label);
    }

    if (resizable) {
        res.resizable({
            alsoResize: '.' + id,
            maxHeight: 16,
            minHeight: 16,
            handles: "e"
        });
    }

    res.click(function () {
        setSortingColumn(this, parent)
    });

    return res;

}

 function createColumnElement(id, label, resizable, sortingColumn) {

    var res = $('<span id="' + id + '" class="' + id + ' list-cell header-label">');

    if (sortingColumn) {
        res.append(label + '<span class="header-sort-order-icon-box"><span class="header-sort-order-icon ui-icon ui-icon-triangle-1-n"></span></span>');
    } else {
        res.append(label + '<span class="header-sort-order-icon-box"><span class="header-sort-order-icon icon-placeholder"></span></span>');
    }

    if (resizable) {
        res.resizable({
            alsoResize: '.' + id,
            maxHeight: 16,
            minHeight: 16,
            handles: "e"
        });
    }

    res.click(function () {
        setSortingColumn(this, sortingColumn);
    });

    return res;

}

 function showCustomAlert(title, outputMsg, btnText) {
    if (!title || !outputMsg || !btnText){
        return;
    }
    $("<div style='padding-top: 10px';></div>").html(outputMsg).dialog({
        resizable: false,
        modal: true,
        autoOpen: true,
        dialogClass: "no-close",
        closeOnEscape: true,
        title: title,
        buttons: [{
            text: btnText,
            click: function() {
              $(this).dialog("destroy").remove();
            }
        }]
    });
}
